import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import Perceivable from './principles/Perceivable'
import Operable from './principles/Operable'
import Understandable from './principles/Understandable'
import Robust from './principles/Robust'

export default function Router({ children }) {
    return <main>
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route path='/principles/perceivable'>
                <Perceivable />
            </Route>
            <Route path='/principles/operable'>
                <Operable />
            </Route>
            <Route path='/principles/understandable'>
                <Understandable />
            </Route>
            <Route path='/principles/robust'>
                <Robust />
            </Route>
        </Switch>
        {children}
    </main>
}
