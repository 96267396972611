import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function Understandable({ children }) {
    return <div>
        <dl>
            <dt>Understandable</dt>
            <dd>
                Does the user understand and able to discern a pattern and define rules to how your application works.
                It is important to follow patterns and <strong>meet users expectations</strong>.
                A good reason to create and follow a <a href='https://www.invisionapp.com/inside-design/guide-to-design-systems/'>design system</a>.
            </dd>
        </dl>

        <h2>Low Hanging Fruit <span className='subtitle'>...or how to make lemonaide</span></h2>
        <ul className='lemons'>
            <li>
                <i className="fas fa-lemon" />
                Identify the primary language of the website.
            </li>
            <li>
                <i className="fas fa-lemon" />
                Be consistent and predictable; use well-worn norms. KISS.
            </li>
            <li>
                <i className="fas fa-lemon" />
                However, if you need to provide contextual help when your interface does.
            </li>
        </ul>
    </div>
}
