import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function Perceivable({ children }) {
    return <div>
        <dl>
            <dt>Perceivable</dt>
            <dd>
                Can your user can identify content and elements by means of (at least one of) their senses.
                For many people, this is <strong>usually visually</strong>. However for others, perceivability may be a matter of <strong>sound or touch</strong>.
                While there are other senses, we really need to make sure that we allow people to perceive our content a number of ways.
            </dd>
        </dl>

        <h2>Low Hanging Fruit <span className='subtitle'>...or how to make lemonaide</span></h2>
        <ul className='lemons'>
            <li>
                <i className="fas fa-lemon" />
                Provide <code>alt</code> content for images, if they are <strong>meaningful</strong>. Likewise, avoid text in images.
            </li>
            <li>
                <i className="fas fa-lemon" />
                Set <code>alt</code> content to <code>''</code> if it is not meaningful, or use <code>aria-hidden='true'</code>
            </li>
            <li>
                <i className="fas fa-lemon" />
                Provide a <code>label</code>, whether aria or explicit for input fields.
            </li>
            <li>
                <i className="fas fa-lemon" />
                You <a href='https://webaim.org/resources/contrastchecker/'>provide sufficient contrast</a> and size for all text to ensure readability.
            </li>
        </ul>

    </div>
}
