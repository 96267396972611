import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function Home({ children }) {
    return <div>
        <dl>
            <dt>Focus on What Matters</dt>
            <dd>
                When we care about how our content is perceived other ways, we <strong>cut out the noise</strong> and
                create <strong>clear signals</strong> for all our users.
            </dd>
        </dl>
    </div>
}
