import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function Operable({ children }) {
    return <div>
        <dl>
            <dt>Operable</dt>
            <dd>
                Does your application allow multiple ways a user can successfully interact with it.
                Again for those with sight, this means visually identifying and interacting with a control, and then <strong>clicking, tapping, or swiping</strong>.
                For others, using a computer <strong>keyboard or voice commands</strong> may be the only means by which they can operate and control the interface.
            </dd>
        </dl>

        <h2>Low Hanging Fruit <span className='subtitle'>...or how to make lemonaide</span></h2>
        <ul className='lemons'>
            <li>
                <i className="fas fa-lemon" />
                You provide <code>:focus</code> states for all your interactive components. Important with keyboard navigation.
            </li>
            <li>
                <i className="fas fa-lemon" />
                Links have a clear purpose. Get out of here, click here.
            </li>
            <li>
                <i className="fas fa-lemon" />
                Create shortcuts to other core sections (e.g. Top Nav, Main Content, The Footer).
            </li>
            <li>
                <i className="fas fa-lemon" />
                Buttons, Links, Active Components are all big enough for touch devices.
            </li>
        </ul>

    </div>
}
