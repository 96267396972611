import React from 'react';
import { BrowserRouter, NavLink } from 'react-router-dom';
import Router from './routes';
import './App.css';

function App() {
  return (
    <BrowserRouter>
        <div className="App">
          <header className="App-header">
            <h1><NavLink to='/'>ariahelp</NavLink></h1>
            <p>Simplicity Benefits Everyone.</p>
          </header>
          <nav>
              <NavLink to='/principles/perceivable' aria-label='Perceivable Principle' activeClassName='active' accessKey="p">
                  <span>P</span>erceivable
              </NavLink>
              <NavLink to='/principles/operable' aria-label='Operable Principle' activeClassName='active' accessKey="o">
                  <span>O</span>perable
              </NavLink>
              <NavLink to='/principles/understandable' aria-label='Understandable Principle' activeClassName='active' accessKey="u">
                  <span>U</span>nderstandable
              </NavLink>
              <NavLink to='/principles/robust' aria-label='Robust Principle' activeClassName='active' accessKey="r">
                  <span>R</span>obust
              </NavLink>
          </nav>
          <Router />
          <footer>
              <hr/>
              <p>Joseph Kelly © 2020</p>
          </footer>
        </div>
    </BrowserRouter>
  );
}

export default App;
