import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function Robust({ children }) {
    return <div>
        <dl>
            <dt>Robust</dt>
            <dd>
                Essentially how well are you keeping up with implementations and ensuring your users have an application
                that <strong>plays well with assisstive technology</strong>.
            </dd>
        </dl>

        <h2>Low Hanging Fruit <span className='subtitle'>...or how to make lemonaide</span></h2>
        <ul className='lemons'>
            <li>
                <i className="fas fa-lemon" />
                Make sure your markup validates.
            </li>
            <li>
                <i className="fas fa-lemon" />
                Use appropriate HTML elements, proper aria labels and use of roles to describe components.
            </li>
        </ul>
    </div>
}
